.dontusethisfornow {
	.container::after,
	.container-fluid::after,
	.row::after {
	  content:"";
	  display:table;
	  clear:both
	}

	.col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
	.col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12,
	.col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
	.col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12,
	.col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
	.col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
	  position:relative;
	  display:block;
	  min-height:1px
	}

	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
	.col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24,
	.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
	.col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24 {
	  float:left
	}

	@media (min-width:576px) {
	 .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
	 .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
	    float:left
	  }
	}
	@media (min-width:768px) {
	 .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
	 .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24 {
	    float:left
	  }
	}
	@media (min-width:992px) {
	 .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
	  .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
	    float:left
	  }
	  .col-lg-8:nth-child(3n + 1) { clear:both;  }
	}
	@media (min-width:1200px) {
	  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
	  .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24 {
	    float:left
	  }
	}

	.nav > .nav-link {  display:inline;  }

	.navigation-container .collapse  {
	  display:block !important;
	  margin-top:0 !important;
	  text-align:right;
	}

	.nav-item {  display:inline-block;  }


	.halo.trustpilot .content,
	.halo.review .content  {
	  text-align:center;

	  .scores {  width:90%;  }
	  * {  margin:inherit auto;  }
	}

	.halo.review .content {  }
	.halo.trustpilot .content {  padding:20% 15% !important;  }

	.boxes  {
	  .justify-content-center {  text-align:center;  }
	}

	body.blog  {
	  form {  display:block;  position:relative;  padding:0 0 2rem;  }
	}

	.comment-card  {
	  .media-body {  width:80%;  }
	}

	header  {
	  &.card.hero,
	  &.hero {  overflow:visible;  }
	}

	.help-search  {
	  width:500px;
	  .form-control, .input-group-addon {  display:inline-block;  }
	  input {  width:80% !important;  }
	}

	.compare.desktop {  display:block !important;  }
	.compare.mobile {  display:none !important;  }

	.compare  {
	  .col  {
	    width:15% !important;
	    display:inline-block;
	  }
	}

	.index .reviews > .media .media-body,
	.index .reviews > .media .d-flex { vertical-align:top; }
	// .index .reviews > .media > .d-flex { float:right; }
	.index .reviews > .media > .media-body { max-width:50%; }

	header.hero .card-img-overlay { padding-top:5rem; padding-bottom:5rem; }
}

html.ie10 {
	[data-step]:before  {
	  padding:1.5rem 0.66rem;
	}
	[data-step] {
	  min-height:405px;
	}
}
