.dontusethisfornow .container::after,
.dontusethisfornow .container-fluid::after,
.dontusethisfornow .row::after {
  content: "";
  display: table;
  clear: both;
}

.dontusethisfornow .col-lg, .dontusethisfornow .col-lg-1, .dontusethisfornow .col-lg-10, .dontusethisfornow .col-lg-11, .dontusethisfornow .col-lg-12,
.dontusethisfornow .col-lg-2, .dontusethisfornow .col-lg-3, .dontusethisfornow .col-lg-4, .dontusethisfornow .col-lg-5, .dontusethisfornow .col-lg-6, .dontusethisfornow .col-lg-7, .dontusethisfornow .col-lg-8, .dontusethisfornow .col-lg-9, .dontusethisfornow .col-md, .dontusethisfornow .col-md-1, .dontusethisfornow .col-md-10, .dontusethisfornow .col-md-11, .dontusethisfornow .col-md-12,
.dontusethisfornow .col-md-2, .dontusethisfornow .col-md-3, .dontusethisfornow .col-md-4, .dontusethisfornow .col-md-5, .dontusethisfornow .col-md-6, .dontusethisfornow .col-md-7, .dontusethisfornow .col-md-8, .dontusethisfornow .col-md-9, .dontusethisfornow .col-sm, .dontusethisfornow .col-sm-1, .dontusethisfornow .col-sm-10, .dontusethisfornow .col-sm-11, .dontusethisfornow .col-sm-12,
.dontusethisfornow .col-sm-2, .dontusethisfornow .col-sm-3, .dontusethisfornow .col-sm-4, .dontusethisfornow .col-sm-5, .dontusethisfornow .col-sm-6, .dontusethisfornow .col-sm-7, .dontusethisfornow .col-sm-8, .dontusethisfornow .col-sm-9, .dontusethisfornow .col-xl, .dontusethisfornow .col-xl-1, .dontusethisfornow .col-xl-10, .dontusethisfornow .col-xl-11, .dontusethisfornow .col-xl-12,
.dontusethisfornow .col-xl-2, .dontusethisfornow .col-xl-3, .dontusethisfornow .col-xl-4, .dontusethisfornow .col-xl-5, .dontusethisfornow .col-xl-6, .dontusethisfornow .col-xl-7, .dontusethisfornow .col-xl-8, .dontusethisfornow .col-xl-9, .dontusethisfornow .col-xs, .dontusethisfornow .col-xs-1, .dontusethisfornow .col-xs-10, .dontusethisfornow .col-xs-11, .dontusethisfornow .col-xs-12,
.dontusethisfornow .col-xs-2, .dontusethisfornow .col-xs-3, .dontusethisfornow .col-xs-4, .dontusethisfornow .col-xs-5, .dontusethisfornow .col-xs-6, .dontusethisfornow .col-xs-7, .dontusethisfornow .col-xs-8, .dontusethisfornow .col-xs-9 {
  position: relative;
  display: block;
  min-height: 1px;
}

.dontusethisfornow .col-xs-1, .dontusethisfornow .col-xs-2, .dontusethisfornow .col-xs-3, .dontusethisfornow .col-xs-4, .dontusethisfornow .col-xs-5, .dontusethisfornow .col-xs-6, .dontusethisfornow .col-xs-7, .dontusethisfornow .col-xs-8, .dontusethisfornow .col-xs-9, .dontusethisfornow .col-xs-10, .dontusethisfornow .col-xs-11, .dontusethisfornow .col-xs-12,
.dontusethisfornow .col-xs-13, .dontusethisfornow .col-xs-14, .dontusethisfornow .col-xs-15, .dontusethisfornow .col-xs-16, .dontusethisfornow .col-xs-17, .dontusethisfornow .col-xs-18, .dontusethisfornow .col-xs-19, .dontusethisfornow .col-xs-20, .dontusethisfornow .col-xs-21, .dontusethisfornow .col-xs-22, .dontusethisfornow .col-xs-23, .dontusethisfornow .col-xs-24,
.dontusethisfornow .col, .dontusethisfornow .col-1, .dontusethisfornow .col-2, .dontusethisfornow .col-3, .dontusethisfornow .col-4, .dontusethisfornow .col-5, .dontusethisfornow .col-6, .dontusethisfornow .col-7, .dontusethisfornow .col-8, .dontusethisfornow .col-9, .dontusethisfornow .col-10, .dontusethisfornow .col-11, .dontusethisfornow .col-12,
.dontusethisfornow .col-13, .dontusethisfornow .col-14, .dontusethisfornow .col-15, .dontusethisfornow .col-16, .dontusethisfornow .col-17, .dontusethisfornow .col-18, .dontusethisfornow .col-19, .dontusethisfornow .col-20, .dontusethisfornow .col-21, .dontusethisfornow .col-22, .dontusethisfornow .col-23, .dontusethisfornow .col-24 {
  float: left;
}

@media (min-width: 576px) {
  .dontusethisfornow .col-sm-1, .dontusethisfornow .col-sm-2, .dontusethisfornow .col-sm-3, .dontusethisfornow .col-sm-4, .dontusethisfornow .col-sm-5, .dontusethisfornow .col-sm-6, .dontusethisfornow .col-sm-7, .dontusethisfornow .col-sm-8, .dontusethisfornow .col-sm-9, .dontusethisfornow .col-sm-10, .dontusethisfornow .col-sm-11, .dontusethisfornow .col-sm-12,
  .dontusethisfornow .col-sm-13, .dontusethisfornow .col-sm-14, .dontusethisfornow .col-sm-15, .dontusethisfornow .col-sm-16, .dontusethisfornow .col-sm-17, .dontusethisfornow .col-sm-18, .dontusethisfornow .col-sm-19, .dontusethisfornow .col-sm-20, .dontusethisfornow .col-sm-21, .dontusethisfornow .col-sm-22, .dontusethisfornow .col-sm-23, .dontusethisfornow .col-sm-24 {
    float: left;
  }
}

@media (min-width: 768px) {
  .dontusethisfornow .col-md-1, .dontusethisfornow .col-md-2, .dontusethisfornow .col-md-3, .dontusethisfornow .col-md-4, .dontusethisfornow .col-md-5, .dontusethisfornow .col-md-6, .dontusethisfornow .col-md-7, .dontusethisfornow .col-md-8, .dontusethisfornow .col-md-9, .dontusethisfornow .col-md-10, .dontusethisfornow .col-md-11, .dontusethisfornow .col-md-12,
  .dontusethisfornow .col-md-13, .dontusethisfornow .col-md-14, .dontusethisfornow .col-md-15, .dontusethisfornow .col-md-16, .dontusethisfornow .col-md-17, .dontusethisfornow .col-md-18, .dontusethisfornow .col-md-19, .dontusethisfornow .col-md-20, .dontusethisfornow .col-md-21, .dontusethisfornow .col-md-22, .dontusethisfornow .col-md-23, .dontusethisfornow .col-md-24 {
    float: left;
  }
}

@media (min-width: 992px) {
  .dontusethisfornow .col-lg-1, .dontusethisfornow .col-lg-2, .dontusethisfornow .col-lg-3, .dontusethisfornow .col-lg-4, .dontusethisfornow .col-lg-5, .dontusethisfornow .col-lg-6, .dontusethisfornow .col-lg-7, .dontusethisfornow .col-lg-8, .dontusethisfornow .col-lg-9, .dontusethisfornow .col-lg-10, .dontusethisfornow .col-lg-11, .dontusethisfornow .col-lg-12,
  .dontusethisfornow .col-lg-13, .dontusethisfornow .col-lg-14, .dontusethisfornow .col-lg-15, .dontusethisfornow .col-lg-16, .dontusethisfornow .col-lg-17, .dontusethisfornow .col-lg-18, .dontusethisfornow .col-lg-19, .dontusethisfornow .col-lg-20, .dontusethisfornow .col-lg-21, .dontusethisfornow .col-lg-22, .dontusethisfornow .col-lg-23, .dontusethisfornow .col-lg-24 {
    float: left;
  }
  .dontusethisfornow .col-lg-8:nth-child(3n + 1) {
    clear: both;
  }
}

@media (min-width: 1200px) {
  .dontusethisfornow .col-xl-1, .dontusethisfornow .col-xl-2, .dontusethisfornow .col-xl-3, .dontusethisfornow .col-xl-4, .dontusethisfornow .col-xl-5, .dontusethisfornow .col-xl-6, .dontusethisfornow .col-xl-7, .dontusethisfornow .col-xl-8, .dontusethisfornow .col-xl-9, .dontusethisfornow .col-xl-10, .dontusethisfornow .col-xl-11, .dontusethisfornow .col-xl-12,
  .dontusethisfornow .col-xl-13, .dontusethisfornow .col-xl-14, .dontusethisfornow .col-xl-15, .dontusethisfornow .col-xl-16, .dontusethisfornow .col-xl-17, .dontusethisfornow .col-xl-18, .dontusethisfornow .col-xl-19, .dontusethisfornow .col-xl-20, .dontusethisfornow .col-xl-21, .dontusethisfornow .col-xl-22, .dontusethisfornow .col-xl-23, .dontusethisfornow .col-xl-24 {
    float: left;
  }
}

.dontusethisfornow .nav > .nav-link {
  display: inline;
}

.dontusethisfornow .navigation-container .collapse {
  display: block !important;
  margin-top: 0 !important;
  text-align: right;
}

.dontusethisfornow .nav-item {
  display: inline-block;
}

.dontusethisfornow .halo.trustpilot .content,
.dontusethisfornow .halo.review .content {
  text-align: center;
}

.dontusethisfornow .halo.trustpilot .content .scores,
.dontusethisfornow .halo.review .content .scores {
  width: 90%;
}

.dontusethisfornow .halo.trustpilot .content *,
.dontusethisfornow .halo.review .content * {
  margin: inherit auto;
}

.dontusethisfornow .halo.trustpilot .content {
  padding: 20% 15% !important;
}

.dontusethisfornow .boxes .justify-content-center {
  text-align: center;
}

.dontusethisfornow body.blog form {
  display: block;
  position: relative;
  padding: 0 0 2rem;
}

.dontusethisfornow .comment-card .media-body {
  width: 80%;
}

.dontusethisfornow header.card.hero, .dontusethisfornow header.hero {
  overflow: visible;
}

.dontusethisfornow .help-search {
  width: 500px;
}

.dontusethisfornow .help-search .form-control, .dontusethisfornow .help-search .input-group-addon {
  display: inline-block;
}

.dontusethisfornow .help-search input {
  width: 80% !important;
}

.dontusethisfornow .compare.desktop {
  display: block !important;
}

.dontusethisfornow .compare.mobile {
  display: none !important;
}

.dontusethisfornow .compare .col {
  width: 15% !important;
  display: inline-block;
}

.dontusethisfornow .index .reviews > .media .media-body,
.dontusethisfornow .index .reviews > .media .d-flex {
  vertical-align: top;
}

.dontusethisfornow .index .reviews > .media > .media-body {
  max-width: 50%;
}

.dontusethisfornow header.hero .card-img-overlay {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

html.ie10 [data-step]:before {
  padding: 1.5rem 0.66rem;
}

html.ie10 [data-step] {
  min-height: 405px;
}
